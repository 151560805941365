var AccessibleDisclosureButton = AccessibleDisclosureButton || {};

(function ($) {
  var contentBlockSitewideBanner = {
    init: function (context) {
      var self = this;
      var disclosureButton;
      var observer = $();
      var moduleId = '';
      var hasModuleCookie = false;

      if (self.attached) {
        return;
      }

      if (window.socialshoppe) {
        return;
      }

      self.attached = true;
      self.$body = $('body');
      self.$header = $('.js-header', context);
      self.$siteHeader = $('.js-site-header', context);
      self.$siteHeaderMain = $('.js-site-header-main', context);
      self.$module = $('.js-content-block-sitewide-banner--v1', self.$body);
      self.$triggers = $('.js-content-block-sitewide-banner-trigger', self.$module);
      self.hideSitewideBannerCheckout = self.$module.hasClass('js-hide-sitewide-banner-checkout');
      self.initialScrollPosition = $(window).scrollTop();
      self.moduleHeight = 0;
      self.siteHeaderHeight = 0;
      self.isModuleHidden = false;
      if (AccessibleDisclosureButton) {
        disclosureButton = new AccessibleDisclosureButton(self.$triggers.get(0));
      }

      if (self.$module.length < 1) {
        return false;
      }

      if (
        self.hideSitewideBannerCheckout &&
        location.pathname.match('/checkout/') &&
        !location.pathname.match('/checkout/confirm.tmpl')
      ) {
        return;
      }

      moduleId = self.$module.data().moduleId;
      hasModuleCookie = $.cookie(moduleId);

      function disableBanner() {
        self.isModuleHidden = true;
        self.$siteHeader.removeClass('content-block-sitewide-banner--enabled');
        self.siteHeaderHeight = self.$siteHeaderMain.outerHeight(true) || 0;
        $(document).trigger('contentBlock.sitewideBanner', [
          0,
          self.siteHeaderHeight - self.moduleHeight
        ]);
        $(window).off('scroll.contentBlock');
        self.$module.detach();
      }

      if (hasModuleCookie) {
        disableBanner();

        return;
      }

      self.$module.removeClass('hidden');
      if (AccessibleDisclosureButton) {
        disclosureButton.init();
      }
      self.$siteHeader.toggleClass('content-block-sitewide-banner--enabled', true);
      self.moduleHeight = self.$module.outerHeight(true);
      self.siteHeaderHeight = self.$siteHeaderMain.outerHeight(true) || 0;
      $(document).trigger('contentBlock.sitewideBanner', [
        self.moduleHeight,
        self.siteHeaderHeight
      ]);

      if (self.$triggers.data('expires')) {
        observer = new MutationObserver(function (mutationList) {
          mutationList.forEach((mutation) => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'aria-expanded') {
              disableBanner();
              self.setCookie();
            }
          });
        });
        observer.observe(self.$triggers.get(0), {
          childList: false,
          attributes: true
        });
      }
    },

    hideOnScrollUp: function () {
      var self = this;
      var currentScroll = $(window).scrollTop();
      var hideModule = false;
      var height = 0;

      if (currentScroll === self.initialScrollPosition) {
        return;
      }
      if (currentScroll < self.initialScrollPosition) {
        self.$body.toggleClass('content-block-sitewide-banner--hidden', false);
        hideModule = false;
      } else if (currentScroll > 5) {
        self.$body.toggleClass('content-block-sitewide-banner--hidden', true);
        hideModule = true;
      }
      if (
        !self.$siteHeader.hasClass('hide-site-header-on-scroll') &&
        self.isModuleHidden !== hideModule
      ) {
        self.isModuleHidden = hideModule;
        self.siteHeaderHeight = self.$siteHeaderMain.outerHeight(true) || 0;
        const newHeight = hideModule ? 0 : self.moduleHeight;
        const newSiteHeaderHeight = hideModule
          ? self.siteHeaderHeight - self.moduleHeight
          : self.siteHeaderHeight + self.moduleHeight;

        $(document).trigger('contentBlock.sitewideBanner', [newHeight, newSiteHeaderHeight]);
      }
      self.$header.trigger('layout.adjustContentPadding');
      self.initialScrollPosition = currentScroll;
    },

    setCookie: function () {
      var self = this;

      $.cookie(self.$triggers.attr('aria-controls'), 1, {
        path: '/',
        expires: self.$triggers.data('expires')
      });
    }
  };

  Drupal.behaviors.contentBlockSitewideBannerV1 = {
    $module: $(),
    $triggers: $(),
    $body: $(),
    initialScrollPosition: 0,
    attached: false,
    attach: function (context) {
      contentBlockSitewideBanner.init(context);

      if (window.socialshoppe) {
        return;
      }
      $(window).on(
        'scroll.contentBlock',
        _.throttle(function () {
          contentBlockSitewideBanner.hideOnScrollUp();
        }, 33)
      );

      $(document).on('contentBlock.signupSuccess', function () {
        contentBlockSitewideBanner.setCookie();
      });
    }
  };
})(jQuery);
